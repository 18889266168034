import {findIndex} from 'lodash';
import {ROLE_ADMIN, ROLE_STORAGE_SENIOR} from "../../../utils/Roles";

class DashboardGuard {

    /**
     * Юзать этот метод
     * @param user
     * @returns {boolean|*}
     */
    canAccess(user) {
        if (findIndex(user.roles, i => i===ROLE_STORAGE_SENIOR) !== -1 || findIndex(user.roles, i => i===ROLE_ADMIN) !== -1) {
            return true;
        }

        return false;
    }

    needRedirect(user) {
        return findIndex(user.roles, i => i===ROLE_STORAGE_SENIOR) !== -1;
    }
}

const Guard = new DashboardGuard();

export {Guard};
