<template>
    <div style="padding: 20px;">
        <div class="text-center">
            <span class="user-avatar">
                <i class="fas fa-user-circle" style="font-size: 100px;"></i>
            </span>
            <h1>Привет, {{ user.username }}</h1>
        </div>
    </div>
</template>

<script>
    import { Guard } from '../productiontask/StorageSeniorDashboard/Guard';

    export default {
        name: "UsersProfile",
        computed: {
            user() {
                return this.$auth.user()
            },
            username() {
                console.log( user );
                return user.fullname ? user.fullname : user.username;
            }
        },
        mounted() {
            if ( Guard.needRedirect( this.$auth.user() ) ) {
                this.$router.push( { name: 'StorageSeniorDashboard' } );
            }
        }
    }
</script>

<style lang='sass' scoped>
    .fa-user-circle
        width: 100px
        height: 100px
        text-align: center
</style>
